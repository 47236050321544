import React from "react";
import Seo from "../../components/seo";
import Layout from "../../Layout";
import Breadcrumbs from "../../components/Breadcrumbs";
import Image1 from "./images/cabbage1.jpg";
import Image2 from "./images/cabbage2.jpg";
import Image3 from "./images/cabbage3.jpg";
import Image4 from "./images/cabbage4.jpg";
import Image5 from "./images/cabbage5.jpg";
import Image6 from "./images/cabbage6.jpg";
import FarmImage1 from "./images/farm1.jpg";
import FarmImage2 from "./images/farm2.jpg";

const images = [Image1, Image2, Image3, Image4, Image5, Image6];

const cabbageBenefits = [
  {
    text: "Boosts Immunity",
    details: "High in Vitamin C, cabbage helps strengthen the immune system.",
  },
  {
    text: "Aids Digestion",
    details: "Rich in fiber, it promotes healthy digestion and regularity.",
  },
  {
    text: "Supports Heart Health",
    details:
      "Antioxidants like anthocyanins help reduce inflammation and support cardiovascular health.",
  },
  {
    text: "Enhances Skin Health",
    details:
      "Vitamin C in cabbage aids collagen production, promoting healthy, youthful skin.",
  },
  {
    text: "Detoxifies the Body",
    details:
      "Sulfur compounds in cabbage support the body’s natural detox processes.",
  },
  {
    text: "Strengthens Bones",
    details: "A good source of Vitamin K, which is vital for bone health.",
  },
];

const CabbagePage = () => (
  <Layout>
    <Seo title="Cabbage" />
    <Breadcrumbs title="Cabbage" />
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-10 col-lg-8">
          <style>
            {`
              .fixed-size { width: 120px; height: 120px; object-fit: cover; margin: 3px; }
              .narrow-paragraph { max-width: 700px; margin: 0 auto; }
              .custom-table { width: 100%; margin-top: 20px; border-collapse: collapse; }
              .custom-table th, .custom-table td { border: 1px solid #ddd; padding: 8px; }
              .custom-table th { background-color: #f2f2f2; text-align: left; }
              .custom-table .green-header { background-color: #d4edda; }
              .margin-bottom { margin-bottom: 50px; }
              .farm-description { display: flex; align-items: center; margin-bottom: 15px; }
              .farm-description img { margin-right: 15px; width: 120px; height: 120px; object-fit: cover; }
              @media (max-width: 767px) {
                .fixed-size { width: 100px; height: 100px; }
                .farm-description { flex-direction: column; align-items: flex-start; }
                .farm-description img { margin-bottom: 10px; width: 100px; height: 100px; }
                .narrow-paragraph { padding: 0 15px; }
              }
            `}
          </style>
          <div className="row">
            <div className="col-12 col-md-8">
              <div className="row">
                {images.slice(0, 3).map((image, index) => (
                  <div className="col-4" key={index}>
                    <img
                      src={image}
                      alt={`Cabbage field ${index + 1}`}
                      className="img-thumbnail fixed-size"
                    />
                  </div>
                ))}
              </div>
              <div className="row mt-2">
                {images.slice(3).map((image, index) => (
                  <div className="col-4" key={index + 3}>
                    <img
                      src={image}
                      alt={`Cabbage field ${index + 4}`}
                      className="img-thumbnail fixed-size"
                    />
                  </div>
                ))}
              </div>
              <div className="row mt-3 narrow-paragraph">
                <div className="col-12">
                  <p>
                    Cabbage is a cruciferous vegetable belonging to the Brassica
                    family, which includes other powerhouses like broccoli,
                    cauliflower, and kale. Widely known for its impressive
                    health benefits, cabbage is a fantastic addition to a
                    healthy, organic diet. Not only is it packed with nutrients,
                    but it's also incredibly versatile and easy to grow in
                    organic gardens, making it an ideal choice for sustainable
                    farming.
                  </p>
                  <p>
                    Organic cabbage is grown without the use of synthetic
                    pesticides or fertilizers, making it a safer, more
                    sustainable choice for both you and the planet. When you
                    choose organic cabbage, you're not only ensuring that you're
                    consuming a healthier product, but you're also supporting
                    farming practices that work in harmony with nature. Organic
                    cabbage tends to have higher levels of certain nutrients and
                    antioxidants compared to conventionally grown varieties.
                    Moreover, organic farming promotes soil health, reduces
                    water pollution, and encourages biodiversity—all crucial
                    aspects of a healthy ecosystem.
                  </p>
                </div>
              </div>
              <h3>
                <strong>Our Organic Farms</strong>
              </h3>
              <div className="farm-description">
                <img
                  src={FarmImage1}
                  alt="Organic Farm 1"
                  className="img-thumbnail"
                />
                <p>Farm at Nuwara Eliya Control Union Certified</p>
              </div>
              <div className="farm-description">
                <img
                  src={FarmImage2}
                  alt="Organic Farm 2"
                  className="img-thumbnail"
                />
                <p>Farm at Chilaw PGS Certified</p>
              </div>
            </div>
            <div className="col-12 col-md-4 ml-auto">
              <h2>Health Benefits of Cabbage</h2>
              <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
                {cabbageBenefits.map((benefit, index) => (
                  <li key={index}>
                    <span style={{ color: "green" }}>✅</span>
                    <span style={{ color: "black" }}>
                      <strong>{benefit.text}:</strong> {benefit.details}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <h3>
            <strong>Nutrition Table</strong>
          </h3>
          <table className="custom-table margin-bottom">
            <thead>
              <tr>
                <th className="green-header">Nutrient</th>
                <th className="green-header">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Calories</td>
                <td>25 kcal</td>
              </tr>
              <tr>
                <td>Protein</td>
                <td>1.28 g</td>
              </tr>
              <tr>
                <td>Fat</td>
                <td>0.1 g</td>
              </tr>
              <tr>
                <td>Carbohydrates</td>
                <td>5.8 g</td>
              </tr>
              <tr>
                <td>Fiber</td>
                <td>2.5 g</td>
              </tr>
              <tr>
                <td>Sugar</td>
                <td>3.2 g</td>
              </tr>
              <tr>
                <td>Vitamin C</td>
                <td>36.6 mg (61% DV)</td>
              </tr>
              <tr>
                <td>Vitamin K</td>
                <td>76 mcg (63% DV)</td>
              </tr>
              <tr>
                <td>Folate</td>
                <td>53 mcg (13% DV)</td>
              </tr>
              <tr>
                <td>Calcium</td>
                <td>40 mg</td>
              </tr>
              <tr>
                <td>Iron</td>
                <td>0.47 mg</td>
              </tr>
              <tr>
                <td>Potassium</td>
                <td>170 mg</td>
              </tr>
            </tbody>
          </table>
          <p>DV = Daily Value based on a 2,000-calorie diet</p>
        </div>
      </div>
    </div>
  </Layout>
);

export default CabbagePage;
